//import loadable from "@loadable/component"
import React, { useState } from "react"
import LazyLoad from "react-lazyload"
import CountUp from "react-countup"
import VisibilitySensor from "react-visibility-sensor"
import Cookies from "js-cookie"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Calculator from "../components/calculator"
import imageQuestion from "../images/question.svg"
import imageSecurity from "../images/security.svg"
import teamImage from "../images/team-2.webp"
import marioSmall from "../images/mario_.webp"
import phillipSmall from "../images/phillip_.webp"
import haraldSmall from "../images/harald_.webp"
import christianSmall from "../images/christian_.webp"
import alexSmall from "../images/alexandra_.webp"
import peterSmall from "../images/peter_.webp"
import nadjaSmall from "../images/nadja_.webp"
import imageCybersicherheit from "../images/Logo_Cybersicherheit.svg"

const IndexPage = () => {
  //Design Variable
  const globalPadding = "p-6 sm:px-10 md:px-20 md:py-8"
  const workshopPadding = "py-6 md:p-6 md:px-10 md:py-8 xl:px-20"

  //Date for Workshop
  const workshopUrl =
    "https://teams.microsoft.com/registration/VCDlcRSTBUWhYRIXqxE4NA,DFVVV7hhIEefsZewK1pZXA,rixSkt5Dp0aC7CMsXeo19g,_AwfILoaXUu8ZXjNdCt8JA,Q-VeoK_7c0qXuS5iOwyAqg,QzziHvpYM0a44OpijA_Jfg?mode=read&tenantId=71e52054-9314-4505-a161-1217ab113834"

  const workshopDate = "Dienstag, 22.03. um 10:00 Uhr"

  //state for the accordion
  const [isActive1, setIsActive1] = useState(false)
  const [isActive2, setIsActive2] = useState(false)
  const [isActive3, setIsActive3] = useState(false)
  const [isActive4, setIsActive4] = useState(false)
  const [isActive5, setIsActive5] = useState(false)
  const [isActive6, setIsActive6] = useState(false)
  const [isActive7, setIsActive7] = useState(false)
  const [isActive8, setIsActive8] = useState(false)

  //Modal states for Pop Up Message
  const [open, setOpen] = useState(false)
  const onOpenModal = () => setOpen(true)
  const onCloseModal = () => setOpen(false)
  const messageDelay = 20000
  let popUpLock = false

  //Track call to action (Calendly Button)
  const CTA = (name, buttonNumber) => {
    if (typeof window !== "undefined") {
      window.analytics.track(name, {
        button: buttonNumber,
      })
    }
  }

  //Checks if the popup visibility area is in sight
  function onChange(isVisible) {
    if (!popUpLock & isVisible & !(Cookies.get("messageShowed") === "true")) {
      CTA("Pop-Up Showed", "")
      popUpLock = true //Prevent more than one popup trigger
      setTimeout(() => {
        onOpenModal()
        Cookies.set("messageShowed", "true")
      }, messageDelay)
    }
  }

  const [finished, setFinished] = React.useState(false)
  const onEnd = () => {
    setFinished(true)
  }

  return (
    <>
      <Layout>
        <Seo
          title="BSI Sicherheitsberatung"
          description="Professionelle BSI Beratung von IT-Sicherheitsexperten. Optimieren Sie Ihre Sicherheitsstrategie und Compliance. Individuelle Lösungen für Ihr Unternehmen."
          keywords="BSI Beratung, IT-Sicherheit, Compliance, Sicherheitsstrategie, IT-Sicherheitsexperte, BSI-Grundschutz"
          canonicalUrl="http://bsi.aroundsec.com/"
          ogImage="/logo_black.svg"
        />

        {/*Wrapper*/}
        <div className="md:py-3 bg-white">
          {/*Maßnahmen*/}

          <section className={`${globalPadding}`}>
            <div className="text-justify mx-auto xl:w-10/12 xl2:w-1/2">
              <div className="flex flex-wrap justify-start mb-10">
                <h2 className="text-left text-3xl lg:text-4xl mb-4 font-bold font-heading leading-tight">
                  <span>
                    Erfahre durch uns, welche der über
                    <span className="heading-red">
                      {" "}
                      1000 BSI-Schutz&shy;maßnahmen
                    </span>{" "}
                    die effektivsten für Dein Unternehmen sind!
                  </span>
                </h2>
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 items-start">
                  <div className="flex order-1 md:order-0 text-gray-800">
                    <div>
                      <p className="mb-6 text-left">
                        Viele Unternehmen stehen vor der Herausforderung, ihre
                        IT-Infrastruktur effektiv gegen Cyberangriffe zu
                        schützen. Obwohl der BSI keine direkte Beratung
                        anbietet, sondern lediglich Empfehlungen ausspricht,
                        bieten wir eine praxisnahe Lösung.{" "}
                        <b>
                          Wir entwickeln eine individuelle
                          Sicherheits&shy;strategie für Ihr Unternehmen
                        </b>
                        , die auf den Prinzipien des BSI-Grundschutzes, der
                        Risikoanalyse und des Notfall&shy;managements, sowie der
                        ISO27001 basiert.
                      </p>
                      <p className="mb-6 text-left">
                        Durch unsere Analyse aus der Perspektive eines
                        Angreifers identifizieren wir die relevantesten
                        BSI-Maßnahmen, um Ihr Unternehmen effizient und
                        wirkungsvoll vor Hacker&shy;angriffen zu schützen. Unser
                        Ansatz garantiert eine gezielte und effektive
                        Absicherung Ihrer IT-Systeme.
                      </p>
                      <div className="lg:text-left text-center">
                        <a
                          href="https://outlook.office365.com/book/BSIBeratung@aroundsec.de/"
                          target="_blank"
                          rel="noreferrer"
                          onClick={CTA.bind(this, "Calendly Button Clicked", 2)}
                        >
                          <button className="btn-red sm:max-w-md shadow-lg shadow-red-500/40">
                            Jetzt optimale BSI-Sicherheits­strategie finden
                          </button>
                        </a>
                      </div>
                    </div>
                  </div>
                  {/*Image*/}
                  <div className="flex order-0 md:order-1 md:hidden lg:block mx-auto">
                    <img
                      className="max-w-xs lg:max-w-sm"
                      src={imageQuestion}
                      height={300}
                      width={300}
                      alt="Fragender Mann"
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-wrap justify-center">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                  <div className="flex order-0">
                    <h2 className="text-left text-3xl lg:text-4xl font-bold font-heading leading-tight">
                      <span>
                        Was macht
                        <span className="heading-red"> aroundsec </span>
                        zum
                      </span>
                      <span className="heading-red"> idealen Partner </span>
                      <span>zur Absicherung Deiner IT-Infrastruktur?</span>
                    </h2>
                  </div>
                  <div className="flex order-1">
                    <div>
                      <p className="text-gray-800 text-left">
                        Unser tiefes Verständnis der Hacker-Mentalität und deren
                        Bedrohung&shy;spotenzial zeichnet uns aus. Wir haben
                        erkannt, dass in der heutigen Zeit nicht nur
                        Groß&shy;unternehmen, sondern insbesondere auch
                        kleinere, unzureichend geschützte Firmen im Fokus der
                        Cyber&shy;kriminalität stehen.
                      </p>
                      <p className="text-gray-800 text-left pt-6">
                        Unsere Mission ist es, Ihr Unternehmen aus der
                        Schusslinie zu nehmen und einen umfassenden Schutz zu
                        gewährleisten. Vertrauen Sie auf aroundsec, um nicht zum
                        Ziel von Hackern zu werden!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-wrap justify-center mt-10">
                <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-8">
                  <div className="p-5 mx-auto bg-gray-50  text-center border-2 rounded-xl border-gray-200 ">
                    <svg
                      className="w-24 h-24 md:w-16 md:h-16 mx-auto mb-4 text-red-500"
                      fill="none"
                      stroke="url(#bgGradient)"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <defs>
                        <linearGradient
                          x1="0%"
                          y1="0%"
                          x2="100%"
                          y2="0%"
                          id="bgGradient"
                        >
                          <stop offset="0%" stopColor="#b70303" />
                          <stop offset="50%" stopColor="#ed4f4f" />
                          <stop offset="100%" stopColor="#b70303" />
                        </linearGradient>
                      </defs>
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
                      />
                    </svg>
                    <h3 className="mb-2 text-xl text-center font-bold font-heading">
                      Unsere Expertise
                    </h3>
                    <p className="text-center text-base text-gray-800">
                      Hacker testen deine IT-Infrastruktur intensiv und geben
                      als Angreifer wertvolle Tipps, um Schwachstellen präventiv
                      zu schließen.
                    </p>
                  </div>
                  <div className="p-5 mx-auto bg-gray-50 text-center border-2 rounded-xl border-gray-200  ">
                    <svg
                      className="w-24 h-24 md:w-16 md:h-16 mx-auto mb-4 text-red-500"
                      fill="none"
                      stroke="url(#bgGradient)"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <defs>
                        <linearGradient
                          x1="0%"
                          y1="0%"
                          x2="100%"
                          y2="0%"
                          id="bgGradient"
                        >
                          <stop offset="0%" stopColor="#b70303" />
                          <stop offset="50%" stopColor="#ed4f4f" />
                          <stop offset="100%" stopColor="#b70303" />
                        </linearGradient>
                      </defs>
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M13 10V3L4 14h7v7l9-11h-7z"
                      />
                    </svg>
                    <h3 className="mb-2 text-xl font-bold font-heading">
                      Unsere Schnelligkeit
                    </h3>
                    <p className="text-center text-base text-gray-800">
                      Schnelle, fokussierte Arbeit ohne Zeitver&shy;schwendung –
                      doppelte Rendite für dich durch effektiven, nachhaltigen
                      Schutz deiner Systeme.
                    </p>
                  </div>
                  <div className="p-5 mx-auto bg-gray-50 text-center border-2 rounded-xl border-gray-200  ">
                    <svg
                      className="w-24 h-24 md:w-16 md:h-16 mx-auto mb-4 text-red-500"
                      fill="none"
                      stroke="url(#bgGradient)"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <defs>
                        <linearGradient
                          x1="0%"
                          y1="0%"
                          x2="100%"
                          y2="0%"
                          id="bgGradient"
                        >
                          <stop offset="0%" stopColor="#b70303" />
                          <stop offset="50%" stopColor="#ed4f4f" />
                          <stop offset="100%" stopColor="#b70303" />
                        </linearGradient>
                      </defs>
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                      />
                    </svg>
                    <h3 className="mb-2 text-xl font-bold font-heading">
                      Unsere Transparenz
                    </h3>
                    <p className="text-center text-base text-gray-800">
                      Wir halten Dich stets informiert über unsere
                      Vorgehens&shy;weisen und Tools, so dass Du jederzeit
                      bewusst Entscheidungen treffen kannst.
                    </p>
                  </div>
                  <div className="p-5 mx-auto bg-gray-50 text-center border-2 rounded-xl border-gray-200  ">
                    <svg
                      className="w-24 h-24 md:w-16 md:h-16 mx-auto mb-4 text-red-500"
                      fill="none"
                      stroke="url(#bgGradient)"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <defs>
                        <linearGradient
                          x1="0%"
                          y1="0%"
                          x2="100%"
                          y2="0%"
                          id="bgGradient"
                        >
                          <stop offset="0%" stopColor="#b70303" />
                          <stop offset="50%" stopColor="#ed4f4f" />
                          <stop offset="100%" stopColor="#b70303" />
                        </linearGradient>
                      </defs>
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"
                      />
                    </svg>
                    <h3 className="mb-2 text-xl font-bold font-heading">
                      Unsere Schulungen
                    </h3>
                    <p className="text-base text-center text-gray-800">
                      Wir schulen deine Mit&shy;arbeiter gezielt, um im
                      Ernstfall eines Cyber&shy;angriffs mit den richtigen Tools
                      und Wissen zu agieren.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/*Big CTA*/}
          <section className="mt-5 py-3 md:pb-0 px-2 bg-red-500 md:bg-gradient-to-r from-red-600 via-red-500 to-red-600">
            <div className="px-4 mx-auto">
              <div className="max-w-xl mx-auto text-center">
                <span className="inline-block text-sm py-1 px-4 bg-white text-red-500 font-semibold rounded-xl">
                  Kosten&shy;lose Sicherheits&shy;beratung
                </span>
                <h2 className="my-3 text-3xl lg:text-4xl text-white font-bold font-heading leading-tight">
                  Schütze Dich vor Hackern!
                </h2>
                <p className="text-center text-semibold text-white">
                  Nutze unsere kostenlose 30-minütige Sicherheitsberatung, um zu
                  erfahren, wie wir Dein Unternehmen in einen formidablen Gegner
                  für Cyberkriminelle verwandeln können!
                </p>
              </div>
            </div>
          </section>

          {/*Sticky Header*/}
          <div className="mb-5 py-2 md:py-3 z-20 top-0 left-0 sticky bg-gray-800 text-center lg:px-2 md:bg-gradient-to-r from-red-600 via-red-500 to-red-600">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://outlook.office365.com/book/BSIBeratung@aroundsec.de/"
              onClick={CTA.bind(this, "Calendly Button Clicked", "Banner")}
              className="cursor-pointer"
            >
              <div
                className="cursor-pointer px-2 py-2 md:bg-white text-white items-center md:text-red-500 leading-none md:rounded-full inline-flex"
                role="alert"
              >
                <span className="md:animate-pulse flex rounded-full text-white bg-red-500 uppercase px-3 py-2 text-xs font-semibold mr-3">
                  Kostenlos
                </span>

                <span className="text-sm md:text-base leading-tight lg:text-xl mr-2 text-center flex-auto">
                  Jetzt BSI-Expertenberatung buchen
                </span>
                <svg
                  className="fill-current opacity-100 h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M12.95 10.707l.707-.707L8 4.343 6.586 5.757 10.828 10l-4.242 4.243L8 15.657l4.95-4.95z" />
                </svg>
              </div>
            </a>
          </div>

          {/*3 Phasen*/}
          <section className={`${globalPadding} lg:pb-10`}>
            <div className="mx-auto xl:w-10/12 xl2:w-1/2">
              <div className="flex flex-wrap justify-center">
                <div className="w-full lg:w-1/2 lg:pr-4">
                  <div className="mb-3 lg:mb-0 lg:pb-0">
                    <h2 className="mb-4 text-3xl lg:text-4xl font-bold font-heading leading-tight">
                      Maximiere Deine IT-Sicherheit mit unseren{" "}
                      <span className="heading-red">3-Phasen-Konzept</span> für
                      blitzschnellen und robusten Cyber-Schutz!
                    </h2>
                    <p className="text-left mb-8 text-gray-800">
                      Unser maßgeschneidertes 3-Phasen-Konzept verpasst Deinem
                      Business einen Turbo-Schutzschild gegen Cyberangriffe –
                      schnell, kosteneffizient und extrem wirksam. Starte jetzt
                      und mach Deine Firma zur digitalen Festung!
                    </p>
                    <a
                      className="text-center"
                      href="https://outlook.office365.com/book/BSIBeratung@aroundsec.de/"
                      onClick={CTA.bind(this, "Calendly Button Clicked", 4)}
                    >
                      <button className="btn-red w-full hidden lg:block shadow-lg shadow-red-500/40">
                        Sichere Dein Unternehmen jetzt ab
                      </button>
                    </a>
                  </div>
                  <LazyLoad once={true} offset={500}>
                    <img
                      className="w-full max-w-xs -mt-10 mb-4 md:hidden lg:h-80 lg:block lg:mt-5 lg:-mb-20 xl:-mt-4 mx-auto"
                      height={300}
                      width={300}
                      src={imageSecurity}
                      alt="Cyber Security"
                    />
                  </LazyLoad>
                </div>
                <div className="w-full lg:w-1/2 mb-10 lg:mb-0">
                  <ul className="space-y-10">
                    <li className="mx-auto flex">
                      <div className="flex flex-wrap flex-col lg:flex-row md:flex-nowrap">
                        <div className="relative order-1 px-4 mx-auto">
                          <div className="z-10 mx-auto absolute">
                            <span className="z-10 flex w-16 h-16 mx-auto items-center justify-center text-3xl font-bold font-heading rounded-full bg-red-500 text-white">
                              1
                            </span>
                          </div>
                          <div className="z-0 animate-ping-slow mx-auto">
                            <span className="flex w-16 h-16 mx-auto items-center bg-red-600 justify-center text-2xl font-bold font-heading rounded-full"></span>
                          </div>
                        </div>
                        <div className="order-2">
                          <h3 className="my-4 text-xl text-left font-semibold leading-tight">
                            Realitätsnahe Cyberangriff-Simulation
                          </h3>
                          <p className="text-left text-gray-800 ">
                            Wir starten mit einer realitätsnahen
                            Hacker-Simulation, um Dein Netzwerk auf
                            Schwachstellen zu überprüfen. Unsere Experten bieten
                            direkt umsetzbare Lösungen, um Dein Unternehmen zur
                            uneinnehmbaren Festung zu machen – mit 100%
                            Engagement für Deine Sicherheit.
                          </p>
                        </div>
                      </div>
                    </li>
                    <li className="mx-auto flex">
                      <div className="flex flex-wrap flex-col lg:flex-row md:flex-nowrap">
                        <div className="relative order-1 px-4 mx-auto">
                          <div className="z-10 mx-auto absolute">
                            <span className="z-10 flex w-16 h-16 mx-auto items-center justify-center text-3xl font-bold font-heading rounded-full bg-red-500 text-white">
                              2
                            </span>
                          </div>
                          <div className="z-0 animate-ping-slow mx-auto">
                            <span className="flex w-16 h-16 mx-auto items-center bg-red-600 justify-center font-bold font-heading rounded-full"></span>
                          </div>
                        </div>
                        <div className="order-2">
                          <h3 className="my-4 text-xl text-left font-semibold leading-tight">
                            Turbo-Schutz mit Härtungsmodulen
                          </h3>
                          <p className="text-left text-gray-800 ">
                            Firewalls und Antivirensoftware decken nur 30-40%
                            des Schutzbedarfs ab. Aroundsec erweitert diesen
                            Schutz durch über 300-600 Härtungskonfigurationen,
                            um Dein Netzwerk umfassend zu sichern. Unsere
                            präventiven Sicherheitsmodule bieten Hackern kaum
                            Angriffsfläche.
                          </p>
                        </div>
                      </div>
                    </li>
                    <li className="mx-auto flex">
                      <div className="flex flex-wrap flex-col lg:flex-row md:flex-nowrap">
                        <div className="relative order-1 px-4 mx-auto">
                          <div className="z-10 mx-auto absolute">
                            <span className="z-10 flex w-16 h-16 mx-auto items-center justify-center text-3xl font-bold font-heading rounded-full bg-red-500 text-white">
                              3
                            </span>
                          </div>
                          <div className="z-0 animate-ping-slow mx-auto">
                            <span className="flex w-16 h-16 mx-auto items-center bg-red-600 justify-center text-2xl font-bold font-heading rounded-full"></span>
                          </div>
                        </div>
                        <div className="order-2">
                          <h3 className="my-4 text-xl text-left font-semibold leading-tight">
                            Stärkung des Menschlichen Faktors
                          </h3>
                          <p className="text-left text-gray-800 ">
                            Mehr als 90% der erfolgreichen Cyberangriffe
                            entstehen durch menschliche Fehler. Daher
                            fokussieren wir in unserer dritten Phase auf den
                            Faktor Mensch, indem wir Deine Mitarbeiter mit
                            unserer Awareness-Kampagne für Cybergefahren
                            sensibilisieren und schulen.
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>

                <a
                  href="https://outlook.office365.com/book/BSIBeratung@aroundsec.de/"
                  onClick={CTA.bind(this, "Calendly Button Clicked", 4)}
                  className="w-full"
                >
                  <button className="btn-red w-full lg:hidden block shadow-lg shadow-red-500/40">
                    Sichere Dein Unternehmen jetzt ab
                  </button>
                </a>
              </div>
            </div>
          </section>
          {/*Calc*/}
          <section className={`${globalPadding}`}>
            <div className="mx-auto xl:w-10/12 xl2:w-1/2">
              <h2 className="text-gray-800 text-3xl lg:text-4xl font-bold font-heading leading-tight text-left mb-4 md:mb-6">
                Berechne die
                <span className="heading-red">
                  {" "}
                  Kosten eines Cyber&shy;angriffs{" "}
                </span>
                für Dein Unternehmen
              </h2>
              <p className="pb-8">
                Unterschätze nicht die Folgen eines Cyberangriffs. In wenigen
                Klicks erfährst Du, welche finanziellen Risiken auf Dein
                Unternehmen zukommen können. Lass uns starten!
              </p>
              <div className="mx-auto text-center">
                <Calculator />
              </div>
            </div>
          </section>
          {/*Team*/}
          <section className={`${globalPadding}`}>
            <div className="mx-auto xl:w-10/12 xl2:w-1/2">
              <h2 className="text-left text-3xl lg:text-4xl font-bold leading-tight text-gray-800 pb-4">
                Wir sind Experten für{" "}
                <span className="heading-red">
                  Cyber-Sicherheit und BSI-Compliance
                </span>
              </h2>
              <div className="flex flex-col gap-8 lg:flex-row justify-center">
                <div className="lg:w-1/2">
                  <p className="text-left font-normal text-base leading-6 text-gray-600">
                    Unser Team: Deine IT-Security-Champions! Wir leben für
                    ehrliche Partnerschaften, sagen Dir klar, was Sache ist –
                    das bringt uns alle weiter. Als junges Unternehmen legen wir
                    größten Wert auf Zuverlässigkeit und Spitzenqualität. Bei
                    uns bist Du in sicheren Händen, denn wir setzen auf
                    maßgeschneiderte Dienstleistungen statt Massenware.
                  </p>
                  <p className="pt-3 pb-6 text-left font-normal text-base leading-6 text-gray-600">
                    Lass uns in einer 30-minütigen Gratisberatung herausfinden,
                    wie wir Dein Unternehmen effektiv vor Cyberangriffen
                    schützen können – BSI-konform und zukunftssicher!
                  </p>
                  <div>
                    <a
                      href="https://outlook.office365.com/book/BSIBeratung@aroundsec.de/"
                      onClick={CTA.bind(this, "Calendly Button Clicked", 4)}
                    >
                      <button className="btn-red shadow-lg shadow-red-500/40 block sm:max-w-md lg:w-full">
                        Jetzt BSI-Gratisberatung sichern
                      </button>
                    </a>
                  </div>
                </div>
                <div className="lg:w-1/2">
                  <LazyLoad once={true} offset={500}>
                    <img
                      className="object-scale-down rounded-xl shadow-xl shadow-black/10"
                      src={teamImage}
                      alt="2 Männer beprechen etwas am PC"
                      width={1280}
                      height={853}
                    />
                  </LazyLoad>
                </div>
              </div>

              <div className="flex flex-col mt-10 justify-center">
                <div className="flex flex-col justify-top">
                  <h2 className="text-left text-3xl lg:text-4xl font-bold leading-9 text-gray-800 pb-4">
                    Bei uns trifft{" "}
                    <span className="heading-red">
                      IT Expertise auf Leidenschaft
                    </span>
                  </h2>
                </div>
                <div className="w-12/12 text-left mb-8">
                  <p className="text-left font-normal text-base text-gray-600">
                    Sicherheit mit Herzblut: Wir bauen Deine digitale Festung!
                    Unsere leidenschaftliche und überzeugte Analyse aus
                    Angreifersicht rüstet Dich gegen Cyberkriminelle. Besonders
                    im Mittelstand, Ziel von 90% der Cyberangriffe, zählt jeder
                    Schutz. Mit individuellen BSI-konformen Strategien, nicht
                    nur Standardlösungen, wird Dein Business zur uneinnehmbaren
                    Bastion. Ergreif die Chance für eine 30-minütige
                    Gratisberatung und stärke Deine IT-Sicherheit!
                  </p>
                </div>
                <div className="grid grid-cols-1 xs:grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 rounded">
                  <div className="p-4 pb-6 flex justify-top flex-col items-center border-2 rounded-xl bg-gray-50 border-gray-200">
                    <LazyLoad once={true} offset={500}>
                      <img
                        className="md:block rounded-xl shadow-xl shadow-black/10"
                        src={marioSmall}
                        alt="Teammitglied Foto"
                        width={300}
                        height={300}
                      />
                    </LazyLoad>
                    <p className="font-semibold text-lg leading-5 text-gray-800 mt-4">
                      Mario
                    </p>
                    <p className="text-sm text-center">
                      Geschäftsführer | IT Sicherheits Spezialist
                    </p>
                    <p className="text-sm text-left pt-3">
                      Mario, der digitale Sherlock Holmes von aroundsec, spürt
                      Sicherheitslücken auf und zimmert daraus unüberwindbare
                      Festungen. Wenn er nicht in der IT-Welt für Sicherheit
                      sorgt, erkundet er die echte Welt auf zwei Rädern.
                    </p>
                  </div>
                  <div className="p-4 pb-6 flex justify-top flex-col items-center border-2 rounded-xl bg-gray-50 border-gray-200">
                    <LazyLoad once={true} offset={500}>
                      <img
                        className="md:block rounded-xl shadow-xl shadow-black/10"
                        src={phillipSmall}
                        alt="Teammitglied Foto"
                        width={300}
                        height={300}
                      />
                    </LazyLoad>
                    <p className="font-semibold text-lg leading-5 text-gray-800 mt-4">
                      Phillip
                    </p>
                    <p className="text-sm text-center">
                      Geschäftsführer | Awareness Spezialist
                    </p>
                    <p className="text-sm text-left pt-3">
                      Phillip, unser IT-Wachsamkeitsguru, ist der kreative Kopf
                      hinter unseren Awareness-Strategien. Er navigiert Kunden
                      durch das Meer der Cybergefahren – und taucht in seiner
                      Freizeit gerne in die Tiefen des Ozeans.
                    </p>
                  </div>
                  <div className="p-4 pb-6 flex justify-top flex-col items-center border-2 rounded-xl bg-gray-50 border-gray-200">
                    <LazyLoad once={true} offset={500}>
                      <img
                        className="md:block rounded-xl shadow-xl shadow-black/10"
                        src={nadjaSmall}
                        alt="Teammitglied Foto"
                        width={300}
                        height={300}
                      />
                    </LazyLoad>
                    <p className="font-semibold text-lg leading-5 text-gray-800 mt-4">
                      Nadja
                    </p>
                    <p className="text-sm text-center">
                      Kaufmännische Leitung | Vertriebsleitung
                    </p>
                    <p className="text-sm text-left pt-3">
                      Nadja, das organisatorische Rückgrat von aroundsec,
                      jongliert Projekte und Vertriebsstrategien mit der
                      Geschicklichkeit einer Radfahrerin und Schwimmerin – stets
                      in Bewegung und immer auf Kurs.
                    </p>
                  </div>
                  <div className="p-4 pb-6 flex justify-top flex-col items-center border-2 rounded-xl bg-gray-50 border-gray-200">
                    <LazyLoad once={true} offset={500}>
                      <img
                        className="md:block rounded-xl shadow-xl shadow-black/10"
                        src={peterSmall}
                        alt="Teammitglied Foto"
                        width={300}
                        height={300}
                      />
                    </LazyLoad>
                    <p className="font-semibold text-lg leading-5 text-gray-800 mt-4">
                      Peter
                    </p>
                    <p className="text-sm text-center">
                      Senior Vertriebsmitarbeiter
                    </p>
                    <p className="text-sm text-left pt-3">
                      Peter, unser Vertriebs-Alchemist, verwandelt
                      Herausforderungen in Lösungen. Mit seiner reichen
                      Erfahrung als Mentor und Abenteurer weiß er, wie man auf
                      Skipisten und im Geschäftsleben den perfekten Schwung
                      findet.
                    </p>
                  </div>
                  <div className="p-4 pb-6 flex justify-top flex-col items-center border-2 rounded-xl bg-gray-50 border-gray-200">
                    <LazyLoad once={true} offset={500}>
                      <img
                        className="md:block rounded-xl shadow-xl shadow-black/10"
                        src={alexSmall}
                        alt="Teammitglied Foto"
                        width={300}
                        height={300}
                      />
                    </LazyLoad>
                    <p className="font-semibold text-lg leading-5 text-gray-800 mt-4">
                      Alexandra
                    </p>
                    <p className="text-sm text-center">
                      Innendienstmitarbeiterin
                    </p>
                    <p className="text-sm text-left pt-3">
                      Alexandra, die musikalische Seele unseres Innendienstes,
                      vereint kaufmännische Expertise mit kreativer Energie.
                      Ihre Leidenschaft für Reisen und Musik macht sie zum
                      harmonischen Zentrum unseres Teams.
                    </p>
                  </div>
                  <div className="p-4 pb-6 flex justify-top flex-col items-center border-2 rounded-xl bg-gray-50 border-gray-200">
                    <LazyLoad once={true} offset={500}>
                      <img
                        className="md:block rounded-xl shadow-xl shadow-black/10"
                        src={christianSmall}
                        alt="Teammitglied Foto"
                        width={300}
                        height={300}
                      />
                    </LazyLoad>
                    <p className="font-semibold text-lg leading-5 text-gray-800 mt-4">
                      Christian
                    </p>
                    <p className="text-sm text-center">Programmierer | Webentwickler</p>
                    <p className="text-sm text-left pt-3">
                    Christian, Webentwickler und Programmierer, begeistert uns durch seine Fotografie und seinen Sportgeist. Sein Know-how in UX und Design verwandelt komplexe Ideen in nutzer&shy;freundliche Anwendungen. Am Meer schöpft er Kraft für neue kreative Vorhaben.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/*Fakten*/}
          <section className={`${globalPadding}`}>
            <div className="mx-auto xl:w-10/12 xl2:w-1/2 text-left">
              <h2 className="text-3xl lg:text-4xl mb-8 font-bold font-heading leading-tight">
                Ein Blick auf
                <span className="heading-red"> unsere Erfolge </span>
              </h2>
              <div className="flex flex-wrap justify-center mx-auto">
                <div className="w-full grid grid-cols-1 md:grid-cols-3 gap-6 rounded">
                  <div className="w-full border-2 rounded-xl bg-gray-50 border-gray-200">
                    <div className="p-3 text-center">
                      <svg
                        className="w-16 h-16 mx-auto mb-2 text-red-500"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentcolor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                        />
                      </svg>
                      <p className="mb-2 text-base font-medium text-gray-800">
                        erfolgreich abgeschlossene Projekte
                      </p>
                      <span className="text-3xl lg:text-4xl font-bold font-heading">
                        <VisibilitySensor
                          partialVisibility
                          offset={{ bottom: 20 }}
                        >
                          {({ isVisible }) => (
                            <div style={{ height: 40 }}>
                              +
                              {isVisible && !finished ? (
                                <CountUp
                                  onEnd={onEnd}
                                  end={500}
                                  duration={3}
                                  useEasing={true}
                                />
                              ) : (
                                500
                              )}
                            </div>
                          )}
                        </VisibilitySensor>
                      </span>
                    </div>
                  </div>
                  <div className="w-full border-2 rounded-xl bg-gray-50 border-gray-200">
                    <div className="p-3 text-center">
                      <svg
                        className="w-16 h-16 mx-auto mb-2 text-red-500"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentcolor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                      <p className="mb-2 text-base font-medium text-gray-800">
                        Jahre geballte Erfahrung
                      </p>
                      <span className="text-3xl lg:text-4xl font-bold font-heading">
                        <VisibilitySensor
                          partialVisibility
                          offset={{ bottom: 20 }}
                        >
                          {({ isVisible }) => (
                            <div style={{ height: 40 }}>
                              +
                              {isVisible && !finished ? (
                                <CountUp
                                  onEnd={onEnd}
                                  end={15}
                                  duration={3}
                                  useEasing={true}
                                />
                              ) : (
                                15
                              )}
                            </div>
                          )}
                        </VisibilitySensor>
                      </span>
                    </div>
                  </div>
                  <div className="w-full border-2 rounded-xl bg-gray-50 border-gray-200">
                    <div className="p-3 text-center">
                      <svg
                        className="w-16 h-16 mx-auto mb-2 text-red-500"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentcolor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                      <p className="mb-2 text-base font-medium text-gray-800">
                        begeisterte Kunden
                      </p>
                      <span className="text-3xl lg:text-4xl font-bold font-heading">
                        {" "}
                        <VisibilitySensor
                          partialVisibility
                          offset={{ bottom: 20 }}
                        >
                          {({ isVisible }) => (
                            <div style={{ height: 40 }}>
                              +
                              {isVisible && !finished ? (
                                <CountUp
                                  onEnd={onEnd}
                                  end={50}
                                  duration={3}
                                  useEasing={true}
                                />
                              ) : (
                                80
                              )}
                            </div>
                          )}
                        </VisibilitySensor>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/*Workshop*/}
          <section className={`${globalPadding}`}>
            <div className="mx-auto xl:w-10/12 xl2:w-1/2">
              <h2 className="text-3xl lg:text-4xl font-bold font-heading leading-tight">
                Unser Engagement in der Allianz für Cybersicherheit:
                <br />
                <span className="heading-red">
                  Gemeinsam stark im digitalen Schutz
                </span>
              </h2>

              <div className="flex flex-col gap-8 lg:flex-row justify-center pt-8">
                <div className="lg:w-2/3">
                  <div className="text-center">
                    <p className="text-left text-gray-800 ">
                      Als aktive Teilnehmer der Allianz für
                      Cyber&shy;sicherheit, initiiert vom Bundesamt für
                      Sicherheit in der Informations&shy;technik (BSI), tragen
                      wir dazu bei, die digitale Sicherheits&shy;landschaft zu
                      stärken. Diese Plattform ermöglicht uns, mit Unternehmen,
                      Verbänden und Behörden zusammen&shy;zuarbeiten, um
                      aktuelle Cyber&shy;bedrohungen zu bekämpfen und effektive
                      Sicherheits&shy;strategien zu teilen. Unser Fokus liegt
                      darauf, praxisnahe und fortschrittliche
                      Cyber-Sicherheits&shy;lösungen zu entwickeln und
                      einzusetzen.
                    </p>
                  </div>
                </div>
                <div className="lg:w-1/3">
                  <div className="flex justify-center align-middle">
                    <a
                      href="https://www.allianz-fuer-cybersicherheit.de/SiteGlobals/Forms/Webs/ACS/Suche/Teilnehmersuche_Formular.html?resourceId=530654&input_=519272&pageLocale=de&teilnehmer=&city=&zipCode=74912&submit=Senden"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <LazyLoad once={true} offset={500}>
                        <img
                          className="w-2/3 md:w-full mx-auto"
                          height={198}
                          width={512}
                          src={imageCybersicherheit}
                          alt="Allianz für Cybersicherheit Logo"
                        />
                      </LazyLoad>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/*Workshop*/}
          {/*    <section className={`${workshopPadding}`}>
            <div className="mx-auto xl:w-10/12 xl2:w-1/2 bg-red-500 md:rounded-xl md:pt-6 px-6 pb-6">
              <div className="flex flex-wrap justify-center">
                <div className="grid grid-cols-1 md:grid-cols-6 md:gap-8">
                  <div className="md:col-span-3 lg:col-span-2 mx-auto self-center">
                    <LazyLoad once={true} offset={500}>
                      <img
                        className="mx-auto"
                        src={hackerAnimation}
                        height={350}
                        width={350}
                        alt="Hacker am Computer"
                      />
                    </LazyLoad>
                  </div>
                  <div className="md:col-span-3 lg:col-span-4">
                    <h2 className="text-center md:text-left text-white text-4xl font-black leading-none mb-2">
                      LIVE HACKING
                    </h2>
                    <h3 className="text-center md:text-left text-white text-xl md:text-2xl font-semibold leading-tight">
                      {`${workshopDate}`}
                    </h3>{" "}
                    <p className="text-justify text-white pt-5">
                      Du bekommst ein genaues Bild davon, wie krimi&shy;nelle
                      Hacker vorgehen und erfährst, welche Werkzeuge
                      ein&shy;gesetzt werden, wie verwund&shy;bar die meisten
                      Unter&shy;nehmen sind und welche schwer&shy;wiegenden
                      Folgen erfolg&shy;reiche Cyber&shy;angriffe haben können.
                    </p>
                    <p className="text-justify text-white py-5">
                      Registriere Dich jetzt kosten&shy;frei und Du erhältst
                      eine Einladung mit Deinen Einwahl&shy;daten für die
                      Hacking Vorführung!
                    </p>
                    <div className="text-center md:text-left">
                      <a
                        href={`${workshopUrl}`}
                        target="_blank"
                        rel="noreferrer"
                        onClick={CTA.bind(this, "Workshop Button Clicked", 1)}
                      >
                        <button className="btn-white-border sm:max-w-md">
                          Jetzt registrieren{" "}
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section> */}
          {/*Testimonials*/}
          <section className={`${globalPadding}`}>
            <div className="mx-auto xl:w-10/12 xl2:w-1/2 text-left">
              <h2 className="mb-6 text-3xl lg:text-4xl font-bold font-heading leading-tight">
                Unsere <span className="heading-red"> Kundenreferenzen</span>
              </h2>

              <div className="mx-auto text-center">
                <p className="text-left text-gray-800 ">
                  Diskretion ist unser Grundsatz – statt Namen zu nennen,
                  sprechen die Branchen unserer Kunden für sich. Absolute
                  Vertraulichkeit ist in unserem Geschäft, insbesondere im
                  sensiblen Bereich der IT-Sicherheit, ein Muss. Einige unserer
                  Kunden haben sich jedoch entschieden, ihre Zufriedenheit
                  öffentlich zu teilen. Wir sind stolz auf diese Empfehlungen
                  und danken für das entgegengebrachte Vertrauen.
                </p>
              </div>
              <div className="mt-8 flex flex-wrap mx-auto justify-center">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6 rounded">
                  <div className="w-full border-2 rounded-xl bg-gray-50 border-gray-200">
                    <div className="p-4 lg:px-10">
                      <div className="flex flex-row justify-center items-center mx-auto h-12 w-auto text-yellow-500 text-2xl">
                        ★ ★ ★ ★ ★
                      </div>
                      <p className="py-2 text-left text-gray-800">
                        Die Zusammen&shy;arbeit mit aroundsec ist ein
                        Volltreffer für uns. Sie sind genau der
                        IT-Security-Partner, den wir gesucht haben. Ihre
                        Expertise in der System&shy;härtung hat uns geholfen,
                        effektive Maßnahmen gegen Cyber&shy;angriffe zu
                        implemen&shy;tieren. Diese Schritte haben nicht nur
                        unsere Sicherheit deutlich erhöht, sondern auch unser
                        Vertrauen in unsere IT-Infrastruktur gestärkt. Kurz
                        gesagt: Mit aroundsec an unserer Seite fühlen wir uns
                        gegen digitale Bedrohungen bestens gewappnet.
                      </p>
                      <div className="flex flex-wrap flex-col">
                        <div className="mx-left">
                          <p className="text-xl">Lars Hartmann</p>
                          <p className="text-black">Leiter IT - TBH GmbH</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-full border-2 rounded-xl bg-gray-50 border-gray-200">
                    <div className="p-4 lg:px-10">
                      <div className="flex flex-row justify-center items-center mx-auto h-12 w-auto text-yellow-500 text-2xl">
                        ★ ★ ★ ★ ★
                      </div>
                      <p className="py-2 text-left text-gray-800">
                        Die Zusammenarbeit macht uns sehr viel Freude! Das
                        Vorgehen ist außerordentlich gut durchdacht und
                        individuell auf unsere Situation abgestimmt. Wir fühlen
                        uns gut aufgehoben, sind mittlerweile deutlich besser
                        vor Cyber&shy;angriffen geschützt und können aroundsec
                        daher nur wärmstens weiterempfehlen.
                      </p>
                      <div className="flex flex-wrap flex-col">
                        <div className="mx-left">
                          <p className="text-xl">Simon Bär</p>
                          <p className="text-black">
                            Kaufmännischer Bereichsleiter - IPR GmbH
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-full border-2 rounded-xl bg-gray-50 border-gray-200">
                    <div className="p-4 lg:px-10">
                      <div className="flex flex-row justify-center items-center mx-auto h-12 w-auto text-yellow-500 text-2xl">
                        ★ ★ ★ ★ ★
                      </div>
                      <p className="py-2 text-left text-gray-800">
                        Dank des durchdachten 3-Phasen-Konzeptes von aroundsec
                        haben wir unsere Verteidigungs&shy;linien massiv
                        verstärkt und stehen Cyber&shy;kriminellen jetzt
                        gestärkt gegenüber. Die Zusammen&shy;arbeit war
                        erstklassig – wir empfehlen aroundsec uneingeschränkt
                        weiter!
                      </p>
                      <div className="flex flex-wrap flex-col">
                        <div className="mx-left">
                          <p className="text-xl">Ivo Jacob</p>
                          <p className="text-black">
                            IT-Leiter - Fripac-Medis GmbH
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/*              <div className="w-full border-2 rounded-xl bg-gray-50 border-gray-200">
                  <div className="p-4 lg:px-10">
                      <div className="flex flex-row justify-center items-center mx-auto h-12 w-auto text-yellow-500 text-2xl">
                        ★ ★ ★ ★ ★
                      </div>
                      <p className="py-2 text-left text-gray-800">
                      Dank des durchdachten 3-Phasen-Konzeptes von aroundsec haben wir unsere Verteidigungslinien massiv verstärkt und stehen Cyberkriminellen jetzt gestärkt gegenüber. Die Zusammenarbeit war erstklassig – wir empfehlen aroundsec uneingeschränkt weiter!
                      </p>
                      <div className="flex flex-wrap flex-col">
                       
                        <div className="mx-auto">
                          <p className="text-xl">Ivo Jacob</p>
                          <p className="text-black">
                            IT-Leiter Fripac-Medis GmbH
                          </p>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  <div className="w-full border-2 rounded-xl bg-gray-50 border-gray-200">
                    <div className="p-4 lg:px-10">
                      <div className="flex flex-row justify-center items-center mx-auto h-12 w-auto text-yellow-500 text-2xl">
                        ★ ★ ★ ★ ★
                      </div>
                      <p className="py-2 text-left text-gray-800">
                        Auf Basis der Schwachstellen&shy;analyse haben wir mit
                        der Implementierung von Härtungs&shy;modulen und der
                        Durchführung einer Awareness-Kampagne unsere
                        IT-Sicherheit nochmals deutlich ausgebaut. Dabei hat uns
                        die strukturierte, mit unserem IT-Dienstleister sehr gut
                        abgestimmte Vorgehensweise von aroundsec überzeugt. Wir
                        sind sehr zufrieden mit der kompetenten Betreuung.
                      </p>
                      <div className="flex flex-wrap flex-col">
                        <div className="mx-left">
                          <p className="text-xl">Elke Kunz-Werthwein</p>
                          <p className="text-black">
                            Informations­sicherheits­beauftragte - phi CAE GmbH
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-full border-2 rounded-xl bg-gray-50 border-gray-200">
                    <div className="p-4 lg:px-10">
                      <div className="flex flex-row justify-center items-center mx-auto h-12 w-auto text-yellow-500 text-2xl">
                        ★ ★ ★ ★ ★
                      </div>
                      <p className="py-2 text-left text-gray-800">
                        Die Simulation realer Hackerangriffe, die von aroundsec
                        durchgeführt wurde, war ein entscheidender Schritt für
                        unser Unternehmen. Sie ermöglichte es uns, kritische
                        Schwachstellen in unserer IT-Infrastruktur zu
                        identifizieren und rechtzeitig wirksame
                        Gegen&shy;maßnahmen zu ergreifen. Diese proaktive
                        Herangehensweise hat unsere Sicherheits&shy;strategie
                        maßgeblich verbessert und uns einen realistischen
                        Einblick in potenzielle Bedrohungs&shy;szenarien
                        gegeben. Diese Erfahrung war für uns äußerst wertvoll
                        und hat unsere Verteidigungs&shy;mechanismen gegen
                        Cyberangriffe erheblich gestärkt.
                      </p>
                      <div className="flex flex-wrap flex-col">
                        <div className="mx-left">
                          <p className="text-xl">Maximilian Schmidt</p>
                          <p className="text-black">
                            Geschäfts­führer - CPI Technologies GmbH
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-full border-2 rounded-xl bg-gray-50 border-gray-200">
                    <div className="p-4 lg:px-10">
                      <div className="flex flex-row justify-center items-center mx-auto h-12 w-auto text-yellow-500 text-2xl">
                        ★ ★ ★ ★ ★
                      </div>
                      <p className="py-2 text-left text-gray-800">
                        Als führendes Familienunternehmen aus Baden-Württemberg
                        sind wir begeistert von aroundsec. Ihr innovativer
                        Ansatz hat unsere IT-Sicherheit revolutioniert.
                        Realistische Hacker-Simulationen deckten Schwachstellen
                        auf, die wir effektiv schlossen. Dank maßgeschneiderter
                        Workshops und Trainings, auch remote, sind unsere
                        Mitarbeiter jetzt bestens gegen Social-Engineering
                        gewappnet. Aroundsecs Expertise und Empathie machen sie
                        zum idealen Partner im Mittelstand.
                      </p>
                      <div className="flex flex-wrap flex-col">
                        <div className="mx-left">
                          {/*  <p className="text-xl">Ivo Jacob</p> */}
                          <p className="text-black">
                            Abteilungsleiter - traditionsreiches Unternehmen
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/*FAQ*/}
          <section className={`${globalPadding}`}>
            <div className="mx-auto xl:w-10/12 xl2:w-1/2 text-left">
              <h2 className="text-gray-800 text-3xl lg:text-4xl font-bold font-heading leading-tight text-left mb-4 md:mb-6">
                Unsere{" "}
                <span className="heading-red">
                  Experten beantworten Deine Fragen
                </span>
              </h2>
              <div className="flex flex-col border-t mx-auto">
                {/* question - start */}
                <div className="border-b">
                  <div
                    className="flex justify-between md:justify-start items-center text-black hover:text-red-500 active:text-red-600 cursor-pointer gap-2 py-4"
                    onClick={() => setIsActive1(!isActive1)}
                    role="none"
                  >
                    <span className="md:text-lg font-semibold ">
                      Ist Firewall und Antivirus nicht genug?
                    </span>
                    {isActive1 ? (
                      <span className="text-red-500 rotate-180">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-6 h-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M19 9l-7 7-7-7"
                          />
                        </svg>
                      </span>
                    ) : (
                      <span className="text-red-500">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-6 h-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M19 9l-7 7-7-7"
                          />
                        </svg>
                      </span>
                    )}
                  </div>
                  {isActive1 && (
                    <p className="text-left text-gray-800 mb-4">
                      <b>Nein, leider nicht.</b> Umfassender Schutz erfordert
                      mehr als nur Firewall und Antivirus. Tauche ein in
                      unsere{" "}
                      <a
                        href="https://www.youtube.com/watch?v=9ByopsZ9_Y8"
                        target="_blank"
                        rel="noreferrer"
                      >
                        „Frage einen Hacker“
                      </a>{" "}
                      Serie auf{" "}
                      <a
                        href="https://www.youtube.com/watch?v=9ByopsZ9_Y8"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <span className="italic text-red-500">YouTube</span>
                      </a>{" "}
                      und entdecke, warum.
                    </p>
                  )}
                </div>
                {/* question - end */}
                {/* question - start */}
                <div className="border-b">
                  <div
                    className="flex justify-between md:justify-start items-center text-black hover:text-red-500 active:text-red-600 cursor-pointer gap-2 py-4"
                    onClick={() => setIsActive2(!isActive2)}
                    role="none"
                  >
                    <span className="md:text-lg font-semibold ">
                      Wieso aroundsec, wenn ich schon einen IT-Dienstleister
                      habe?
                    </span>
                    {isActive2 ? (
                      <span className="text-red-500 rotate-180">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-6 h-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M19 9l-7 7-7-7"
                          />
                        </svg>
                      </span>
                    ) : (
                      <span className="text-red-500">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-6 h-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M19 9l-7 7-7-7"
                          />
                        </svg>
                      </span>
                    )}
                  </div>
                  {isActive2 && (
                    <p className="text-left text-gray-800 mb-4">
                      <b>Wir ergänzen Deine IT-Dienstleister perfekt.</b>{" "}
                      IT-Security ist ein dynamisches Feld, ständig entwickeln
                      sich neue Angriffsmethoden. Ein Spezialist wie aroundsec
                      stellt sicher, dass Dein immer einen Schritt voraus ist.
                    </p>
                  )}
                </div>
                {/* question - end */}
                {/* question - start */}
                <div className="border-b">
                  <div
                    className="flex justify-between md:justify-start items-center text-black hover:text-red-500 active:text-red-600 cursor-pointer gap-2 py-4"
                    onClick={() => setIsActive3(!isActive3)}
                    role="none"
                  >
                    <span className="md:text-lg font-semibold ">
                      Was sind die häufigsten Angriffsmethoden auf Unternehmen?
                    </span>
                    {isActive3 ? (
                      <span className="text-red-500 rotate-180">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-6 h-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M19 9l-7 7-7-7"
                          />
                        </svg>
                      </span>
                    ) : (
                      <span className="text-red-500">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-6 h-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M19 9l-7 7-7-7"
                          />
                        </svg>
                      </span>
                    )}
                  </div>
                  {isActive3 && (
                    <p className="text-left text-gray-800 mb-4">
                      Finden Sie es heraus in unserer{" "}
                      <a
                        href="https://www.youtube.com/watch?v=Ld2MWNq4ISU"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <span className="italic text-red-500">YouTube</span>
                      </a>{" "}
                      -Serie{" "}
                      <a
                        href="https://www.youtube.com/watch?v=Ld2MWNq4ISU"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {" "}
                        „Frage einen Hacker“
                      </a>{" "}
                      . Wir zeigen Dir, wie Angreifer ticken und Dein
                      Unternehmen bedrohen könnten.
                    </p>
                  )}
                </div>
                {/* question - end */}
                {/* question - start */}
                <div className="border-b">
                  <div
                    className="flex justify-between md:justify-start items-center text-black hover:text-red-500 active:text-red-600 cursor-pointer gap-2 py-4"
                    onClick={() => setIsActive4(!isActive4)}
                    role="none"
                  >
                    <span className="md:text-lg font-semibold ">
                      Was genau macht aroundsec?
                    </span>
                    {isActive4 ? (
                      <span className="text-red-500 rotate-180">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-6 h-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M19 9l-7 7-7-7"
                          />
                        </svg>
                      </span>
                    ) : (
                      <span className="text-red-500">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-6 h-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M19 9l-7 7-7-7"
                          />
                        </svg>
                      </span>
                    )}
                  </div>
                  {isActive4 && (
                    <p className="text-left text-gray-800 mb-4">
                      Wir schlüpfen in die Rolle eines Hackers, um Deine
                      IT-Infrastruktur auf Herz und Nieren zu prüfen. Wir machen
                      Dein Unternehmen für Cyberkriminelle unattraktiv. Denn
                      Wissen ist Macht – und Sicherheit.
                    </p>
                  )}
                </div>
                {/* question - end */}
                {/* question - start */}
                <div className="border-b">
                  <div
                    className="flex justify-between md:justify-start items-center text-black hover:text-red-500 active:text-red-600 cursor-pointer gap-2 py-4"
                    onClick={() => setIsActive5(!isActive5)}
                    role="none"
                  >
                    <span className="md:text-lg font-semibold ">
                      Wie groß ist das Team von aroundsec?
                    </span>
                    {isActive5 ? (
                      <span className="text-red-500 rotate-180">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-6 h-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M19 9l-7 7-7-7"
                          />
                        </svg>
                      </span>
                    ) : (
                      <span className="text-red-500">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-6 h-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M19 9l-7 7-7-7"
                          />
                        </svg>
                      </span>
                    )}
                  </div>
                  {isActive5 && (
                    <p className="text-left text-gray-800 mb-4">
                      Mit unseren freien Mitarbeitern sind wir ein starkes Team
                      von 7 Experten. Seit unserer Gründung 2021 haben wir ein
                      rasantes Wachstum erlebt, dank der steigenden Nachfrage
                      nach effektiver IT-Sicherheit.
                    </p>
                  )}
                </div>
                {/* question - end */}
                {/* question - start */}
                <div className="border-b">
                  <div
                    className="flex justify-between md:justify-start items-center text-black hover:text-red-500 active:text-red-600 cursor-pointer gap-2 py-4"
                    onClick={() => setIsActive6(!isActive6)}
                    role="none"
                  >
                    <span className="md:text-lg font-semibold ">
                      Warum bieten wir eine kostenlose Erstberatung an?
                    </span>
                    {isActive6 ? (
                      <span className="text-red-500 rotate-180">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-6 h-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M19 9l-7 7-7-7"
                          />
                        </svg>
                      </span>
                    ) : (
                      <span className="text-red-500">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-6 h-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M19 9l-7 7-7-7"
                          />
                        </svg>
                      </span>
                    )}
                  </div>
                  {isActive6 && (
                    <p className="text-left text-gray-800 mb-4">
                      Wir möchten das Bewusstsein für IT-Sicherheit stärken. Die
                      Gefahren sind real und oft unterschätzt. Unser Ziel ist
                      es, Aufklärung zu bieten und Dein Unternehmen vor
                      verheerenden Cyberangriffen zu schützen.
                    </p>
                  )}
                </div>
                {/* question - end */}
                {/* question - start */}
                <div className="border-b">
                  <div
                    className="flex justify-between md:justify-start items-center text-black hover:text-red-500 active:text-red-600 cursor-pointer gap-2 py-4"
                    onClick={() => setIsActive7(!isActive7)}
                    role="none"
                  >
                    <span className="md:text-lg font-semibold ">
                      Wie integriert aroundsec BSI-Grundschutz und
                      ISO-Zertifizierung in seine Strategie?
                    </span>
                    {isActive7 ? (
                      <span className="text-red-500 rotate-180">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-6 h-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M19 9l-7 7-7-7"
                          />
                        </svg>
                      </span>
                    ) : (
                      <span className="text-red-500">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-6 h-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M19 9l-7 7-7-7"
                          />
                        </svg>
                      </span>
                    )}
                  </div>
                  {isActive7 && (
                    <p className="text-left text-gray-800 mb-4">
                      Aroundsec nutzt BSI-Standards und andere international anerkannte Managementsysteme, um Dein Unternehmen umfassend zu schützen. Wir
                      navigieren Sie durch die Komplexität und stellen sicher,
                      dass Ihre IT-Sicherheit den höchsten Standards entspricht.
                    </p>
                  )}
                </div>
                {/* question - end */}
                {/* question - start */}
                <div className="border-b">
                  <div
                    className="flex justify-between md:justify-start items-center text-black hover:text-red-500 active:text-red-600 cursor-pointer gap-2 py-4"
                    onClick={() => setIsActive8(!isActive8)}
                    role="none"
                  >
                    <span className="md:text-lg font-semibold ">
                      Wie kann aroundsec mein Unternehmen vor den steigenden
                      Cyberbedrohungen schützen?
                    </span>
                    {isActive8 ? (
                      <span className="text-red-500 rotate-180">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-6 h-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M19 9l-7 7-7-7"
                          />
                        </svg>
                      </span>
                    ) : (
                      <span className="text-red-500">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-6 h-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M19 9l-7 7-7-7"
                          />
                        </svg>
                      </span>
                    )}
                  </div>
                  {isActive8 && (
                    <p className="text-left text-gray-800 mb-4">
                      Mit unserem 3-Phasen-Konzept, basierend auf realistischen
                      Hacker-Simulationen, Härtungsmodulen und
                      Awareness-Kampagnen, machen wir Dein Unternehmen zur
                      uneinnehmbaren Festung – BSI-konform und zukunftssicher.
                    </p>
                  )}
                </div>
                {/* question - end */}
              </div>
            </div>
          </section>
          {/*The visibility sensor for the popup is activated here*/}
          {/* <VisibilitySensor
            partialVisibility
            onChange={onChange}
            offset={{ bottom: 10 }}
          >
            <div style={{ height: 1 }}></div>
          </VisibilitySensor> */}
          {/*<button onClick={onOpenModal}>Open modal</button>*/}
          {/*  <Modal open={open} onClose={onCloseModal} focusTrapped={false} center>
            <div
              id="wrapper"
              className="flex items-center min-h-screen md:min-h-0 bg-gray-100 z-80"
            >
              <div className="bg-gray-100 max-w-3xl mx-auto">
                <div className="items-center flex flex-col md:flex-row overflow-hidden py-2">
                  <div className="w-full md:w-2/5 sm:h-auto order-first sm:order-none bg-gray-100">
                    <LazyLoad once={true} offset={500}>
                      <img
                        className="mx-auto"
                        src={hackerAnimation}
                        height={300}
                        width={300}
                        alt="Hacker am Computer"
                      />
                    </LazyLoad>
                  </div>
                  <div className="bg-gray-100 w-full md:w-3/5 flex flex-col px-5 sm:px-8">
                    <div className="bg-gray-100 md:py-5">
                      <h2 className="text-center md:text-left leading-none text-gray-800 text-4xl md:text-3xl lg:text-4xl font-bold mb-2">
                        <span className="text-transparent text-live font-black animate-pulse">
                          LIVE{" "}
                        </span>
                        <span className="font-black">HACKING</span>
                      </h2>
                      <h3 className="text-center md:text-left leading-tight font-semibold">{`${workshopDate}`}</h3>

                      <p className="text-justify text-gray-800 py-5 max-w-xl">
                        Erlebe innerhalb von 30 Minuten live, wie kriminelle
                        Hacker vorgehen. Mache Dir ein Bild davon, wie einfach
                        es ist, Systeme zu kompro&shy;mittieren und Deine
                        Unternehmens&shy;daten zu stehlen!
                      </p>
                      <div className="text-center mx-auto w-full">
                        <a
                          href={`${workshopUrl}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <button className="btn-red max-w-md md:w-full">
                            Jetzt registrieren
                          </button>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal> */}
        </div>
      </Layout>
    </>
  )
}

export default IndexPage
