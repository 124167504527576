import React from "react"
import Slider from "react-rangeslider"
import { numberFormat, numberFormat2 } from "../lib"
import Select from "react-select"

class Calculator extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      amountRevenue: 0.1,
      daysInterruption: 1,
      daysSpecialIT: 1,
      checkedInternIT: false,
      checkedSpecialIT: false,
      checkedRansomeware: false,
      checkedPrivacy: false,
      checkedMarketing: false,
      crimeMoney: 0,
      selectedValue: "",
      resultSum: 0,
    }

    this.handleRevenueChange = this.handleRevenueChange.bind(this)
    this.handleDropDownChange = this.handleDropDownChange.bind(this)
    this.handleInterruptChange = this.handleInterruptChange.bind(this)
    this.handleCrimeMoneyChange = this.handleCrimeMoneyChange.bind(this)
    this.handleSpecialITDays = this.handleSpecialITDays.bind(this)
    this.handleChangeOne = this.handleChangeOne.bind(this)
    this.handleChangeTwo = this.handleChangeTwo.bind(this)
    this.handleChangeTree = this.handleChangeTree.bind(this)
    this.handleChangeFour = this.handleChangeFour.bind(this)
    this.handleChangeFive = this.handleChangeFive.bind(this)
  }

  //Track call to action
  CTA = (name, buttonNumber) => {
    if (typeof window !== "undefined") {
      window.analytics.track(name, {
        value: buttonNumber,
      })
    }
  }

  handleRevenueChange = value => {
    this.setState({ amountRevenue: value })
  }

  handleInterruptChange = value => {
    this.setState({ daysInterruption: value })
  }

  handleCrimeMoneyChange = value => {
    this.setState({ crimeMoney: value })
  }

  handleSpecialITDays = value => {
    this.setState({ daysSpecialIT: value })
  }

  handleChangeOne = e => {
    this.setState({
      checkedInternIT: e.target.checked,
    })
    if (typeof window !== "undefined") {
      this.CTA("Calculator", "Intern IT: " + e.target.checked)
    }
  }

  handleChangeTwo = e => {
    this.setState({
      checkedSpecialIT: e.target.checked,
    })
    if (typeof window !== "undefined") {
      this.CTA("Calculator", "IT-Forensics: " + e.target.checked)
    }
  }

  handleChangeTree = e => {
    this.setState({
      checkedRansomeware: e.target.checked,
    })
    if (typeof window !== "undefined") {
      this.CTA("Calculator", "Ransomeware: " + e.target.checked)
    }
  }

  handleChangeFour = e => {
    this.setState({
      checkedPrivacy: e.target.checked,
    })
    if (typeof window !== "undefined") {
      this.CTA("Calculator", "Data Leak: " + e.target.checked)
    }
  }

  handleChangeFive = e => {
    this.setState({
      checkedMarketing: e.target.checked,
    })
    if (typeof window !== "undefined") {
      this.CTA("Calculator", "Marketing: " + e.target.checked)
    }
  }

  handleDropDownChange = selected => {
    this.setState({ selectedValue: selected })
    if (typeof window !== "undefined") {
      this.CTA("Calculator", selected.label)
    }
  }

  render() {
    let {
      amountRevenue,
      daysInterruption,
      checkedInternIT,
      checkedSpecialIT,
      checkedRansomeware,
      selectedValue,
      checkedPrivacy,
      checkedMarketing,
      crimeMoney,
      resultSum,
      daysSpecialIT,
    } = this.state

    const customStyles = {
      placeholder: () => ({
        color: "black",
      }),
    }

    const theme = theme => ({
      ...theme,
      colors: {
        ...theme.colors,
        primary: "#E50404",
        primary25: "#FFFFFF",
      },
    })

    const options = [
      {
        label: "Bis 9 Mitarbeiter",
        value: "1",
      },
      {
        label: "10-49 Mitarbeiter",
        value: "2",
      },
      {
        label: "50-249 Mitarbeiter",
        value: "3",
      },
      {
        label: "250-500 Mitarbeiter",
        value: "4",
      },
      {
        label: "500-1000 Mitarbeiter",
        value: "5",
      },
      {
        label: "> 1000 Mitarbeiter",
        value: "6",
      },
    ]

    const renderRevenue = () => {
      return (
        <h3 className="text-center">
          {numberFormat2(amountRevenue * 1000000)}
        </h3>
      )
    }

    const renderDays = days => {
      if (days === "0") {
        return (
          <h3 className="text-green-600 text-center">
            {Number(days.toFixed(1))} Tage
          </h3>
        )
      }
      if (days > 0 && days < 2) {
        return (
          <h3 className="text-green-600 text-center">
            {Number(days.toFixed(1))} Tag
          </h3>
        )
      }
      if (days > 1 && days < 6) {
        return (
          <h3 className="text-green-600 text-center">
            {Number(days.toFixed(1))} Tage
          </h3>
        )
      }
      if (days > 5 && days < 11) {
        return (
          <h3 className="text-yellow-600 text-center">
            {Number(days.toFixed(1))} Tage
          </h3>
        )
      }
      if (days > 10) {
        return (
          <h3 className="text-red-500 text-center">
            {Number(days.toFixed(1))} Tage
          </h3>
        )
      }
    }

    const renderResult = () => {
      let colorClass = ""
      if (selectedValue.value > 0) {
        if (selectedValue.value === "1") {
          resultSum += amountRevenue * 10000 * 0.274
          resultSum = resultSum * daysInterruption

          if (checkedInternIT) {
            resultSum += 2274
          }
          if (checkedSpecialIT) {
            resultSum = resultSum + daysSpecialIT * 1000
          }
          if (checkedMarketing) {
            resultSum += 500
          }
        }
        if (selectedValue.value === "2") {
          resultSum += amountRevenue * 10000 * 0.394
          resultSum = resultSum * daysInterruption
          if (checkedInternIT) {
            resultSum += 8500
          }
          if (checkedSpecialIT) {
            resultSum = resultSum + daysSpecialIT * 1000 * 1.1
          }

          if (checkedMarketing) {
            resultSum += 7500
          }
        }
        if (selectedValue.value === "3") {
          resultSum += amountRevenue * 10000 * 0.5
          resultSum = resultSum * daysInterruption
          if (checkedInternIT) {
            resultSum += 18500
          }
          if (checkedSpecialIT) {
            resultSum = resultSum + daysSpecialIT * 1000 * 1.2
          }

          if (checkedMarketing) {
            resultSum += 20000
          }
        }
        if (selectedValue.value === "4") {
          resultSum += amountRevenue * 10000 * 0.6
          resultSum = resultSum * daysInterruption
          if (checkedInternIT) {
            resultSum += 20000
          }
          if (checkedSpecialIT) {
            resultSum = resultSum + daysSpecialIT * 1000 * 1.3
          }

          if (checkedMarketing) {
            resultSum += 20000
          }
        }
        if (selectedValue.value === "5") {
          resultSum += amountRevenue * 10000 * 0.8
          resultSum = resultSum * daysInterruption
          if (checkedInternIT) {
            resultSum += 25000
          }
          if (checkedSpecialIT) {
            resultSum = resultSum + daysSpecialIT * 1000 * 1.4
          }

          if (checkedMarketing) {
            resultSum += 25000
          }
        }
        if (selectedValue.value === "6") {
          resultSum += amountRevenue * 10000
          resultSum = resultSum * daysInterruption
          if (checkedInternIT) {
            resultSum += 30000
          }
          if (checkedSpecialIT) {
            resultSum = resultSum + daysSpecialIT * 1000 * 1.5
          }

          if (checkedMarketing) {
            resultSum += 28000
          }
        }
      } else {
        resultSum = 0
      }

      if (checkedRansomeware) {
        resultSum += crimeMoney
      }

      if (selectedValue.value > 0) {
        if (checkedPrivacy) {
          resultSum += amountRevenue * 1000000 * 0.04
        }
      }

      resultSum > 0
        ? (colorClass = "text-red-500")
        : (colorClass = "text-black")

      return (
        <h2 className={`${colorClass} lg:text-4xl font-bold text-center`}>
          {numberFormat(resultSum)}
        </h2>
      )
    }

    const formatMio = value => {
      return value.toFixed(1) + " Mio"
    }

    const formatMio2 = value => {
      return value.toFixed(1) / 1000000 + " Mio"
    }

    return (
      <div className="App">
        <div
          id="wrapper"
          className="pt-8 pb-5 px-5 flex flex-wrap justify-start rounded-t-xl bg-gray-50 border-t-2 border-l-2 border-r-2 border-b-2 border-gray-200"
        >
          <div className="w-full md:w-1/2 md:px-4 xl:px-8">
            <p className="text-xl text-center md:text-left pb-3">
              <b>1. </b>Wie viele Mitarbeiter hat Dein Unternehmen? Wähle aus!
            </p>
            <div className="mx-auto max-w-md">
              <Select
                options={options}
                style={customStyles}
                theme={theme}
                placeholder={"Bitte auswählen!"}
                onChange={this.handleDropDownChange}
                value={this.state.selectedValue}
              />
            </div>

            <div className="mx-auto max-w-md">
              <p className="text-xl text-center md:text-left mt-6 pb-3">
                <b>2. </b>Dein Jahresumsatz: Wähle einen Betrag!
              </p>

              <Slider
                value={amountRevenue}
                orientation="horizontal"
                step={0.1}
                min={0.1}
                max={10}
                tooltip={true}
                format={formatMio}
                onChange={this.handleRevenueChange}
              />
              <div className="text-center font-semibold">{renderRevenue()}</div>
            </div>

            <div className="mx-auto max-w-md">
              <p className="text-xl text-center md:text-left mt-6 pb-3">
                <b>3. </b>Wie lange könnte ein Angriff Dein Business lahmlegen?
                Wähle die Tage!
              </p>
              <Slider
                value={daysInterruption}
                orientation="horizontal"
                step={1}
                min={1}
                max={20}
                tooltip={true}
                onChange={this.handleInterruptChange}
              />

              <div className="text-center font-semibold md:pb-5">
                {renderDays(daysInterruption)}
              </div>
            </div>
          </div>
          <div className="w-full md:w-1/2 md:px-4 xl:px-8 pt-5 md:pt-0">
            <p className="text-xl text-center md:text-left pb-3">
              <b>4. </b>Entscheidende Faktoren für die
              Cyberangriff-Kostenanalyse.
            </p>
            <div className="mx-auto">
              <div className="grid grid-cols-5 items-center pb-6">
                <div className="col-span-1 text-right">
                  <label className="switch">
                    <input
                      id="toggle"
                      type="checkbox"
                      checked={this.state.checkedInternIT}
                      onChange={this.handleChangeOne}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
                <div className="pl-3 col-span-4 text-left leading-none">
                  <label htmlFor="toggle">War Dein IT-Team im Einsatz?</label>
                </div>
              </div>

              <div className="grid grid-cols-5 items-center pb-6">
                <div className="col-span-1 text-right">
                  <label className="switch">
                    <input
                      id="toggle2"
                      type="checkbox"
                      checked={this.state.checkedSpecialIT}
                      onChange={this.handleChangeTwo}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
                <div className="pl-3 col-span-4 text-left leading-none">
                  <label htmlFor="toggle2">
                    Wurden externe IT-Experten hinzugezogen?
                  </label>
                </div>
              </div>
              {this.state.checkedSpecialIT ? (
                <div className="mb-4 max-w-md mx-auto">
                  <Slider
                    value={daysSpecialIT}
                    orientation="horizontal"
                    step={1}
                    min={1}
                    max={20}
                    tooltip={true}
                    onChange={this.handleSpecialITDays}
                  />

                  <div className="text-center font-semibold md:pb-5">
                    {renderDays(daysSpecialIT)}
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className="grid grid-cols-5 items-center pb-6">
                <div className="col-span-1 text-right">
                  <label className="switch">
                    <input
                      id="toggle3"
                      type="checkbox"
                      checked={this.state.checkedRansomeware}
                      onChange={this.handleChangeTree}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
                <div className="pl-3 col-span-4 text-left leading-none">
                  <label htmlFor="toggle3">
                    Musste ein Lösegeld bezahlt werden?
                  </label>
                </div>
              </div>
              {this.state.checkedRansomeware ? (
                <div className="mb-4 max-w-md mx-auto">
                  <Slider
                    value={crimeMoney}
                    orientation="horizontal"
                    step={10000}
                    min={0}
                    max={1000000}
                    format={formatMio2}
                    tooltip={true}
                    onChange={this.handleCrimeMoneyChange}
                  />
                  <h3 className="text-center font-semibold">
                    {numberFormat2(crimeMoney)}
                  </h3>
                </div>
              ) : (
                ""
              )}

              <div className="grid grid-cols-5 items-center gap-y-6 pb-6">
                <div className="col-span-1 text-right">
                  <label className="switch">
                    <input
                      id="toggle4"
                      type="checkbox"
                      checked={this.state.checkedPrivacy}
                      onChange={this.handleChangeFour}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
                <div className="pl-3 col-span-4 text-left leading-none">
                  <label htmlFor="toggle4">
                    Gab es einen Verstoß gegen die DSGVO?
                  </label>
                </div>
                <div className="col-span-1 text-right">
                  <label className="switch">
                    <input
                      id="toggle5"
                      type="checkbox"
                      checked={this.state.checkedMarketing}
                      onChange={this.handleChangeFive}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
                <div className="pl-3 col-span-4 text-left leading-none">
                  <label htmlFor="toggle5">
                    Hat der Angriff das Image Deines Unternehmens beschädigt?
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full mx-auto bg-gray-100 border-b-2 border-l-2 border-r-2 border-gray-200 rounded-b-xl p-5">
          <div className="text-center font-semibold md:text-xl xl:text-2xl">
            Geschätzte Gesamtkosten des Cyberangriffs
          </div>
          {renderResult()}
          <div className="w-full italic text-center text-gray-500 text-xs">
            *Diese Werte sind Schätzungen und können variieren.
            <br />
            Kontaktiere uns für eine detaillierte Analyse!
          </div>
        </div>
      </div>
    )
  }
}

export default Calculator
